(function($) {
    'use strict';

    $('.sub-nav-list').hide();
    $('.hamburger-sub').on('click, mouseover', function()    {
        $('.sub-nav-list').toggle({
            duration: 400,
            queue: false,
        });
    });

    $(".sub-nav-list").mouseleave(function() {
        $('.sub-nav-list').hide();
    });

    var rap = [];
    $('.js-request-access').change(function()   {
        if($(this).is(':checked'))
            rap[$(this).data('product-id')] = $(this).data('product-name');
        else
            delete rap[$(this).data('product-id')];
    });

    $('#js-request-access-submit').click(function() {
        $('#js-request-access-message').val(rap.filter(function(x)   {
            return x !== '';
        }).join(','));
        return true;
    });

    if($('#js-pq').length > 0)  {
        var refs = [];
        var noResultsEl = $('.js-no-results');
        var resultsEl = $('.js-results');
        $('.js-product-category').each(function(i, c)  {
            var $c = $(c);
            var r = {
                category: $c,
                products: []
            };
            $c.find('.js-product').each(function(i, p)   {
                var $p = $(p);
                r.products.push($p);
            });
            refs.push(r);
        });

        $('#js-pq').keyup(function()    {
            var noResults = true;
            var q = $(this).val().toLowerCase();
            $('#js-pq-val').text(q);
            refs.forEach(function(ref)    {
                var $c = ref.category;
                $c.hide = true;
                ref.products.forEach(function($p) {
                    if(q !== '' && $p.find('h1').text().toLowerCase().indexOf(q) === -1)  {
                        $p.fadeOut();
                    } else {
                        $c.hide = false;
                        $p.fadeIn();
                    }
                });
                if($c.hide)
                    $c.fadeOut();
                else    {
                    $c.fadeIn();
                    noResults = false;
                }
            });
            if(noResults)   {
                noResultsEl.show();
                resultsEl.hide();
            }else{
                document.location.hash = q;
                noResultsEl.hide();
                resultsEl.show();
            }
        });
    }
})(jQuery);
